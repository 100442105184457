import { editedMeldSupportedCurrencyToCurrencyInfo } from 'graphql/data/types'
import { useActiveLocalCurrency } from 'hooks/useActiveLocalCurrency'
import { useActiveLocale } from 'hooks/useActiveLocale'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useChangellyFiatSupportedCurrenciesQuery,
  useChangellySupportedCurrenciesQuery,
  useFiatOnRampAggregatorSupportedTokensQuery,
  useGetSupportedTokensQuery,
} from 'uniswap/src/features/fiatOnRamp/api'
import { FORCountry, FiatCurrencyInfo, FiatOnRampCurrency, SupportedFlow } from 'uniswap/src/features/fiatOnRamp/types'
import { getFiatCurrencyComponents } from 'utilities/src/format/localeBased'
import { getFiatCurrencyName } from 'utils/fiatCurrency'

type FiatOnRampCurrencyInfo = {
  meldSupportedFiatCurrency: FiatCurrencyInfo
  notAvailableInThisRegion: boolean
}

const fallbackCurrencyInfo: FiatCurrencyInfo = {
  ...getFiatCurrencyComponents('en-US', 'USD'),
  symbol: '$',
  name: 'United States Dollar',
  shortName: 'USD',
  code: 'USD',
}

/**
 * Returns the meld-supported fiat currency info based on user-selected country,
 * the local currency active in the user's app settings, with a fallback to USD.
 */
export function useMeldFiatCurrencyInfo(selectedCountry?: FORCountry): FiatOnRampCurrencyInfo {
  const { data: supportedFiatCurrencies } = useChangellyFiatSupportedCurrenciesQuery({
    providerCode: 'moonpay',
    supportedFlow: 'buy',
    type: 'fiat',
  })

  const activeLocale = useActiveLocale()
  const activeLocalCurrency = useActiveLocalCurrency()
  const { t } = useTranslation()

  const appFiatCurrencySupported =
    supportedFiatCurrencies &&
    supportedFiatCurrencies.fiatCurrencies.some(
      (currency): boolean => activeLocalCurrency.toLowerCase() === currency.fiatCurrencyCode.toLowerCase(),
    )
  const meldSupportedFiatCurrency: FiatCurrencyInfo = useMemo(() => {
    const activeLocalCurrencyComponents = getFiatCurrencyComponents(activeLocale, activeLocalCurrency)
    const { name, shortName } = getFiatCurrencyName(t, activeLocalCurrency)
    const activeLocalCurrencyFiatCurrencyInfo: FiatCurrencyInfo = {
      ...activeLocalCurrencyComponents,
      name,
      shortName,
      code: activeLocalCurrency,
    }
    return appFiatCurrencySupported ? activeLocalCurrencyFiatCurrencyInfo : fallbackCurrencyInfo
  }, [activeLocalCurrency, activeLocale, appFiatCurrencySupported, t])

  return {
    meldSupportedFiatCurrency,
    notAvailableInThisRegion: supportedFiatCurrencies?.fiatCurrencies?.length === 0,
  }
}

// export type FiatOnRampCurrency = {
//   currencyInfo: Maybe<CurrencyInfo> | Maybe<BlockchainTokenInfo>
//   moonpayCurrencyCode?: string
//   meldCurrencyCode?: string
// }

export function useFiatOnRampSupportedTokens(
  supportedFlow: SupportedFlow,
  fiatCurrency: FiatCurrencyInfo,
  countryCode?: string,
): FiatOnRampCurrency[] {
  const { data: quoteCurrencyOptions } = useGetSupportedTokensQuery({
    fiatCurrency: fiatCurrency.code,
    countryCode: countryCode ?? 'US',
    provider: 'PAYPAL',
    direction: 'BUY',
  })
  // const { data: quoteCurrencyOptions } = useGetSupportedTokensQuery({
  //   provider: 'PAYPAL',
  //   direction: 'BUY',
  // })

  // const data = useChangellySupportedCurrenciesQuery({providerCode: ProviderCode.MOONPAY, supportedFlow: SupportedFlow.BUY})

  return useMemo(() => {
    return (
      // quoteCurrencyOptions?.supportedTokens?.filter(currency => currency.chainId).map((currency) => {
      quoteCurrencyOptions?.supportedTokens
        ?.map((currency) => {
          const meldCurrencyCode = currency.cryptoCurrencyCode
          // const currencyInfo = meldSupportedCurrencyToCurrencyInfo(currency)
          const currencyInfo = editedMeldSupportedCurrencyToCurrencyInfo(currency)
          console.log('currencies', meldCurrencyCode, currency, currencyInfo)
          return { currencyInfo, meldCurrencyCode }
        })
        .filter((currency) => currency.currencyInfo) ?? []
    )
  }, [quoteCurrencyOptions?.supportedTokens])
}
