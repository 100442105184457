import { skipToken } from '@reduxjs/toolkit/query/react'
import { buildCurrencyInfo } from 'constants/routing'
import { nativeOnChain } from 'constants/tokens'
import { t } from 'i18next'
import { useFiatOnRampSupportedTokens, useMeldFiatCurrencyInfo } from 'pages/Swap/Buy/hooks'
import { Dispatch, PropsWithChildren, SetStateAction, createContext, useContext, useMemo, useState } from 'react'
import {
  useChangellyOnRampSellQuoteQuery,
  useChangellySupportedCountriesQuery,
} from 'uniswap/src/features/fiatOnRamp/api'
import {
  FORCountry,
  FORQuoteResponse,
  FORSupportedCountriesResponse,
  FiatCurrencyInfo,
  FiatOnRampCurrency,
  SupportedFlow,
} from 'uniswap/src/features/fiatOnRamp/types'
import {
  InvalidRequestAmountTooLow,
  isFiatOnRampApiError,
  isInvalidRequestAmountTooHigh,
  isInvalidRequestAmountTooLow,
} from 'uniswap/src/features/fiatOnRamp/utils'
import { UniverseChainId } from 'uniswap/src/types/chains'
import { NumberType, useFormatter } from 'utils/formatNumbers'
import { useAccount } from 'wagmi'

class SellFormError extends Error {
  constructor(public readonly message: string) {
    super(message)
  }
}

type SellFormState = {
  readonly inputAmount: string
  readonly quoteCurrency: FiatOnRampCurrency
  readonly selectedCountry?: FORCountry
  readonly countryModalOpen: boolean
  readonly currencyModalOpen: boolean
  readonly providerModalOpen: boolean
}

type SellInfo = {
  readonly meldSupportedFiatCurrency?: FiatCurrencyInfo
  readonly notAvailableInThisRegion: boolean
  readonly countryOptionsResult?: FORSupportedCountriesResponse
  readonly supportedTokens?: FiatOnRampCurrency[]
  readonly amountOut?: string
  readonly quotes?: FORQuoteResponse
  readonly fetchingQuotes: boolean
  readonly error?: SellFormError
}

type SellFormContextType = {
  sellFormState: SellFormState
  setSellFormState: Dispatch<SetStateAction<SellFormState>>
  derivedSellFormInfo: SellInfo
}

export const ethCurrencyInfo = buildCurrencyInfo(nativeOnChain(UniverseChainId.Mainnet))
const DEFAULT_BUY_FORM_STATE: SellFormState = {
  inputAmount: '',
  quoteCurrency: {
    currencyInfo: ethCurrencyInfo,
    meldCurrencyCode: 'ETH',
  },
  selectedCountry: undefined,
  countryModalOpen: false,
  currencyModalOpen: false,
  providerModalOpen: false,
}

export const SellFormContext = createContext<SellFormContextType>({
  sellFormState: DEFAULT_BUY_FORM_STATE,
  setSellFormState: () => undefined,
  derivedSellFormInfo: {
    meldSupportedFiatCurrency: undefined,
    notAvailableInThisRegion: false,
    countryOptionsResult: undefined,
    supportedTokens: [],
    amountOut: undefined,
    quotes: undefined,
    fetchingQuotes: false,
    error: undefined,
  },
})

export function useSellFormContext() {
  return useContext(SellFormContext)
}

function useDerivedSellFormInfo(state: SellFormState): SellInfo {
  const account = useAccount()
  // const amountOut = useUSDTokenUpdater(
  //   true /* inputInFiat */,
  //   state.inputAmount,
  //   state.quoteCurrency?.currencyInfo?.currency,
  // )
  const { formatNumberOrString } = useFormatter()

  const { meldSupportedFiatCurrency, notAvailableInThisRegion } = useMeldFiatCurrencyInfo(state.selectedCountry)
  // const { data: countryOptionsResult } = useFiatOnRampAggregatorCountryListQuery()
  const { data: countryOptionsResult } = useChangellySupportedCountriesQuery({
    providerCode: 'moonpay',
    supportedFlow: 'sell',
  })
  const supportedTokens = useFiatOnRampSupportedTokens(SupportedFlow.SELL, meldSupportedFiatCurrency)

  console.log('sell context', state.selectedCountry, state.quoteCurrency, meldSupportedFiatCurrency)
  const {
    data: quotes,
    isFetching: fetchingQuotes,
    error: quotesError,
    // } = useFiatOnRampAggregatorCryptoQuoteQuery(
  } = useChangellyOnRampSellQuoteQuery(
    // state.inputAmount &&
    //   state.inputAmount !== '' &&
    state.selectedCountry?.countryCode && state.quoteCurrency && meldSupportedFiatCurrency
      ? {
          // sourceAmount: parseFloat(state.inputAmount),
          sourceAmount: 1,
          sourceCurrencyCode: state.quoteCurrency.meldCurrencyCode ?? 'ETH',
          destinationCurrencyCode: meldSupportedFiatCurrency.code,
          countryCode: state.selectedCountry.countryCode,
          state: state.selectedCountry.state,
        }
      : skipToken,
    {
      refetchOnMountOrArgChange: true,
    },
  )

  // const filteredQuotes =
  //   quotes && quotes.quotes
  //     ? {
  //         ...quotes,
  //         quotes: quotes.quotes.filter((quote) => quote.serviceProvider === 'MOONPAY'),
  //       }
  //     : undefined

  const amountOut = useMemo(() => {
    console.log('amount out', state.inputAmount, quotes?.quotes)
    if (quotes?.quotes && quotes?.quotes[0] && state.inputAmount) {
      return (parseFloat(quotes?.quotes[0].rate) * parseFloat(state.inputAmount)).toFixed(2)
    }
    return undefined
  }, [quotes, state.inputAmount])

  const error = useMemo(() => {
    if (quotesError && isFiatOnRampApiError(quotesError)) {
      if (isInvalidRequestAmountTooLow(quotesError)) {
        const formattedAmount = formatNumberOrString({
          input: (quotesError as InvalidRequestAmountTooLow).data.context.minimumAllowed,
          type: NumberType.FiatTokenQuantity,
        })
        return new SellFormError(t(`fiatOnRamp.error.min`, { amount: formattedAmount }))
      }
      if (isInvalidRequestAmountTooHigh(quotesError)) {
        const formattedAmount = formatNumberOrString({
          input: quotesError.data.context.maximumAllowed,
          type: NumberType.FiatTokenQuantity,
        })
        return new SellFormError(t(`fiatOnRamp.error.max`, { amount: formattedAmount }))
      }
      return new SellFormError(t('common.somethingWentWrong.error'))
    }
    return undefined
  }, [formatNumberOrString, quotesError])

  return useMemo(
    () => ({
      amountOut,
      notAvailableInThisRegion,
      meldSupportedFiatCurrency,
      supportedTokens,
      countryOptionsResult,
      quotes,
      fetchingQuotes,
      error,
    }),
    [
      amountOut,
      countryOptionsResult,
      error,
      fetchingQuotes,
      meldSupportedFiatCurrency,
      notAvailableInThisRegion,
      quotes,
      supportedTokens,
    ],
  )
}

export function SellFormContextProvider({ children }: PropsWithChildren) {
  const [sellFormState, setSellFormState] = useState<SellFormState>({ ...DEFAULT_BUY_FORM_STATE })
  const derivedSellFormInfo = useDerivedSellFormInfo(sellFormState)

  const value = useMemo(
    () => ({
      sellFormState,
      setSellFormState,
      derivedSellFormInfo,
    }),
    [sellFormState, derivedSellFormInfo],
  )

  return <SellFormContext.Provider value={value}>{children}</SellFormContext.Provider>
}
