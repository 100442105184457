import { ColumnCenter } from 'components/Column'
import { useScroll } from 'hooks/useScroll'
import { Trans } from 'i18n'
import { Box } from 'pages/Landing/components/Generics'
import { Hover, RiseIn } from 'pages/Landing/components/animations'
import { FC } from 'react'
import { ChevronDown } from 'react-feather'
import styled from 'styled-components'
import { BREAKPOINTS } from 'theme'
import { Text } from 'ui/src'
import { heightBreakpoints } from 'ui/src/theme'

type ScrollToLearnProps = {
  scrollToRef: () => void
}

const LearnMoreContainer = styled(Box)`
  bottom: 48px;
  @media (max-width: ${BREAKPOINTS.md}px) {
    bottom: 100px;
  }

  // Prevent overlap of Hero text and Learn More button on short screens
  @media (max-height: ${heightBreakpoints.short + 30}px) {
    display: none;
  }
`

export const ScrollToLearn: FC<ScrollToLearnProps> = ({ scrollToRef }) => {
  const { height: scrollPosition } = useScroll()
  const translateY = -scrollPosition / 7
  const opacityY = 1 - scrollPosition / 1000

  return (
    <LearnMoreContainer
      position="absolute"
      width="100%"
      align="center"
      justify="center"
      pointerEvents="none"
      style={{ transform: `translate(0px, ${translateY}px)`, opacity: opacityY }}
    >
      <RiseIn delay={0.3}>
        <Box
          direction="column"
          align="center"
          justify="flex-start"
          onClick={() => scrollToRef()}
          style={{ cursor: 'pointer' }}
          width="500px"
        >
          <Hover>
            <ColumnCenter>
              <Text variant="body2">
                <Trans i18nKey="hero.scroll" />
              </Text>
              <ChevronDown />
            </ColumnCenter>
          </Hover>
        </Box>
      </RiseIn>
    </LearnMoreContainer>
  )
}
