import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { MoonpayEventName } from '@uniswap/analytics-events'
import { config } from 'uniswap/src/config'
import { uniswapUrls } from 'uniswap/src/constants/urls'
import { objectToQueryString, serializeQueryParams } from 'uniswap/src/data/utils'
import { FOR_API_HEADERS } from 'uniswap/src/features/fiatOnRamp/constants'
import {
  ChangellyOffersResponse,
  CreateOrderRequest,
  CreateOrderSellRequest,
  CreateOrderResponse,
  FORGetCountryResponse,
  FORQuoteRequest,
  FORQuoteResponse,
  FORServiceProvidersResponse,
  FORSupportedCountriesResponse,
  FORSupportedFiatCurrenciesResponse,
  FORSupportedTokensRequest,
  FORSupportedTokensResponse,
  FORTransactionRequest,
  FORTransactionResponse,
  FORQuote,
} from 'uniswap/src/features/fiatOnRamp/types'
import { transformPaymentMethods } from 'uniswap/src/features/fiatOnRamp/utils'
import { sendAnalyticsEvent } from '../telemetry/send.native'

export const fiatOnRampAggregatorApi = createApi({
  reducerPath: 'fiatOnRampAggregatorApi-uniswap',
  baseQuery: fetchBaseQuery({
    baseUrl: uniswapUrls.backendApiUrl,
    headers: FOR_API_HEADERS,
  }),
  endpoints: (builder) => ({
    createOrder: builder.query<{ paymentUrl: string }, CreateOrderRequest>({
      query: (data) => ({
        url: '/order',
        method: 'POST',
        body: data,
      }),
    }),
    getBuyQuote: builder.query<FORQuoteResponse, FORQuoteRequest>({
      query: (query) => `/currencies/buy-quote?${serializeQueryParams(query)}`,
    }),
    getSupportedTokens: builder.query<FORSupportedTokensResponse, FORSupportedTokensRequest>({
      query: (query) => `/currencies/tokens?${serializeQueryParams(query)}`,
    }),
    fiatOnRampAggregatorCountryList: builder.query<FORSupportedCountriesResponse, void>({
      query: () => `/supported-countries`,
    }),
    fiatOnRampAggregatorTransferServiceProviders: builder.query<FORServiceProvidersResponse, void>({
      query: () => '/transfer-service-providers',
      keepUnusedDataFor: 60 * 60, // 1 hour
    }),
    fiatOnRampAggregatorSupportedTokens: builder.query<FORSupportedTokensResponse, FORSupportedTokensRequest>({
      query: (request) => `/supported-tokens?${new URLSearchParams(request).toString()}`,
    }),
    /**
     * Fetches a fiat onramp transaction by its ID, with no signature authentication.
     */
    fiatOnRampAggregatorTransaction: builder.query<
      FORTransactionResponse,
      // TODO: make sessionId required in FORTransactionRequest after backend is updated
      Omit<FORTransactionRequest, 'sessionId'> & { sessionId: string }
    >({
      query: (request) => `/transaction?${objectToQueryString(request)}`,
    }),
  }),
})

export const {
  useGetBuyQuoteQuery,
  useCreateOrderQuery,
  useGetSupportedTokensQuery,
  useFiatOnRampAggregatorCountryListQuery,
  useFiatOnRampAggregatorTransferServiceProvidersQuery,
  useFiatOnRampAggregatorSupportedTokensQuery,
} = fiatOnRampAggregatorApi


const castChangellyToOriginal = (changellyResponse: ChangellyOffersResponse, currencyFrom: string, currencyTo: string, countryCode: string): FORQuoteResponse => {
  try {
    const urlByProvider = {
      MOONPAY: 'https://moonpay.com',
      BANXA: 'https://banxa.com/',
      WERT: 'https://wert.io/',
      TRANSAK: 'https://transak.com/',
      SIMPLEX: 'https://www.simplex.com/',
      SWITCHERE: 'https://switchere.com/'
    }

    const logoByProvider = {
      MOONPAY: 'https://images-serviceprovider.meld.io/MOONPAY/short_logo_light.png',
      BANXA: 'https://images-serviceprovider.meld.io/BANXA/short_logo_light.png',
      WERT: 'https://www.eu-startups.com/wp-content/uploads/2021/02/Wert.png',
      TRANSAK: 'https://images-serviceprovider.meld.io/TRANSAK/short_logo_light.png',
      SIMPLEX: 'https://images-serviceprovider.meld.io/SIMPLEX/short_logo_light.png',
      SWITCHERE: 'https://switchere.com/'
    }


    const quotes: FORQuote[] = changellyResponse.filter(offer => !!offer.rate && offer.providerCode !== 'switchere').map((offer) => {
      const totalFee = parseFloat(offer.fee);
      const sourceAmount = parseFloat(offer.amountFrom);
      const destinationAmount = parseFloat(offer.amountExpectedTo);
      const serviceProvider = offer.providerCode.toLocaleUpperCase() as keyof typeof logoByProvider;

      return {
        countryCode,
        sourceAmount,
        serviceProvider,
        destinationAmount,
        rate: offer.invertedRate,
        sourceCurrencyCode: currencyFrom,
        destinationCurrencyCode: currencyTo,
        serviceProviderDetails: {
          paymentMethods: offer.paymentMethodOffer.map(item => item.methodName),
          serviceProvider: offer.providerCode,
          name: serviceProvider,
          url: urlByProvider[serviceProvider],
          logos: {
            darkLogo: logoByProvider[serviceProvider],
            lightLogo: logoByProvider[serviceProvider]
          }
        },
        totalFee
      };
    });

    console.log('final quotes', quotes)
    return {
      quotes,
      message: null,  // You can customize this based on the logic
      error: null,    // Handle errors accordingly
    };
  } catch (error: any) {
    return {
      quotes: null,
      message: null,
      error: error.message || "An error occurred while casting the response."
    };
  }
};

export const fiatOnRampApiChangelly = createApi({
  reducerPath: 'fiatOnRampApiChangelly',
  baseQuery: fetchBaseQuery({ 
    baseUrl: config.changellyApiProxyUrl, 
   }),
  endpoints: (builder) => ({
    changellySupportedCountries: builder.query<FORSupportedCountriesResponse, {
      providerCode?: string
      supportedFlow?: string
    }>({
      queryFn: ({providerCode, supportedFlow}) =>
        // TODO: [MOB-223] consider a reverse proxy for privacy reasons
        fetch(`${config.changellyApiProxyUrl}/supported-countries?${serializeQueryParams({
          // providerCode,
          supportedFlow,
        })}`)
          .then((response) => response.json())
          .then((response: FORSupportedCountriesResponse) => {
            // sendAnalyticsEvent(MoonpayEventName.MOONPAY_GEOCHECK_COMPLETED, {
            //   success: true,
            //   networkError: false,
            // })
            return { data: response }
          })
          .catch((e) => {
            // sendAnalyticsEvent(MoonpayEventName.MOONPAY_GEOCHECK_COMPLETED, {
            //   success: false,
            //   networkError: true,
            // })

            return { data: undefined, error: e }
          }),
    }),
    changellyFiatSupportedCurrencies: builder.query<
      FORSupportedFiatCurrenciesResponse,
      {
        type?: string
        providerCode?: string
        supportedFlow?: string 
      }
    >({
      queryFn: ({ type, providerCode, supportedFlow }) =>
        fetch(`${config.changellyApiProxyUrl}/fiat-currencies?${serializeQueryParams({type, providerCode, supportedFlow})}`)
          .then((response) => response.json())
          .then((response: FORSupportedFiatCurrenciesResponse) => {
            return { data: response }
          })
          .catch((e) => {
            return { data: undefined, error: e }
          }),
    }),
    changellyOnRampBuyQuote: builder.query<
      FORQuoteResponse,
      FORQuoteRequest
    >({
      queryFn: ({ countryCode, destinationCurrencyCode, sourceAmount, sourceCurrencyCode, walletAddress }) =>
        fetch(
          `${config.changellyApiProxyUrl}/buy-offers?${serializeQueryParams({
            // providerCode: 'moonpay',
            currencyTo: destinationCurrencyCode,
            currencyFrom: sourceCurrencyCode,
            amountFrom: sourceAmount,
            country: countryCode,
          })}`,
        )
        .then((response) => response.json())
        .then((response: ChangellyOffersResponse) => {
          console.log('buy offers response', destinationCurrencyCode, sourceCurrencyCode, response)
          const castedResponse = castChangellyToOriginal(response, sourceCurrencyCode, destinationCurrencyCode, countryCode);
          return { data: castedResponse };
        })
        .catch((e) => {
          return { data: undefined, error: e }
        }),
    }),
    changellyOnRampSellQuote: builder.query<
      FORQuoteResponse,
      FORQuoteRequest
    >({
      queryFn: ({ countryCode, destinationCurrencyCode, sourceAmount, sourceCurrencyCode, walletAddress }) =>
        fetch(
          `${config.changellyApiProxyUrl}/sell-offers?${serializeQueryParams({
            // providerCode: 'moonpay',
            currencyTo: destinationCurrencyCode,
            currencyFrom: sourceCurrencyCode,
            amountFrom: sourceAmount,
            country: countryCode,
          })}`,
        )
        .then((response) => response.json())
        .then((response: ChangellyOffersResponse) => {
          console.log('sell offers', countryCode, destinationCurrencyCode, sourceCurrencyCode, response)
          const castedResponse = castChangellyToOriginal(response, sourceCurrencyCode, destinationCurrencyCode, countryCode);
          return { data: castedResponse };
        })
        .catch((e) => {
          return { data: undefined, error: e }
        }),
    }),

    changellyOnRampWidgetUrl: builder.query<{ redirectUrl: string }, CreateOrderRequest>({
      queryFn: async (data) => {
        try {
          const response = await fetch(`${config.changellyApiProxyUrl}/onramp`, {
            body: JSON.stringify(data),
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
          });

          console.log('onramp url', data, response)

          if (!response.ok) {
            const errorText = await response.text();
            return { error: { status: 'FETCH_ERROR', error: errorText } };
          }

          const responseData: CreateOrderResponse = await response.json();

          return { data: { redirectUrl: responseData.redirectUrl } };
        } catch (error) {
          console.log('error fetch', error)
          return { error: { status: 'FETCH_ERROR', error: String(error) } };
        }
      },
    }),

    changellyOffRampWidgetUrl: builder.query<{ redirectUrl: string }, CreateOrderSellRequest>({
      queryFn: async (data) => {
        try {
          const response = await fetch(`${config.changellyApiProxyUrl}/offramp`, {
            body: JSON.stringify(data),
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
          });

          console.log('offramp url', data, response)
          if (!response.ok) {
            const errorText = await response.text();
            return { error: { status: 'FETCH_ERROR', error: errorText } };
          }

          const responseData: CreateOrderResponse = await response.json();

          return { data: { redirectUrl: responseData.redirectUrl } };
        } catch (error) {
          console.log('error fetch', error)
          return { error: { status: 'FETCH_ERROR', error: String(error) } };
        }
      },
    }),

    changellySupportedCurrencies: builder.query<FORSupportedTokensResponse, {
      type?: string
      providerCode?: string
      supportedFlow?: string 
    }>({
      query: ({ type, providerCode, supportedFlow }) => 
        `${config.changellyApiProxyUrl}/currencies?${serializeQueryParams({type, providerCode, supportedFlow})}`
    }),

    changellyGetCountry: builder.query<FORGetCountryResponse, void>({
      query: () => `${config.changellyApiProxyUrl}/get-country`,
    }),
  })
})

export const {
  useChangellyGetCountryQuery,
  useChangellySupportedCountriesQuery,
  useChangellyOnRampWidgetUrlQuery,
  useChangellyOffRampWidgetUrlQuery,
  useChangellyFiatSupportedCurrenciesQuery,
  useChangellySupportedCurrenciesQuery,
  useChangellyOnRampBuyQuoteQuery,
  useChangellyOnRampSellQuoteQuery,
} = fiatOnRampApiChangelly